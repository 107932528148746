<template>
  <div class="security-section">
    <div class="section-header">
      <h2 class="section-title">{{ getHeader.title }}</h2>
      <p>
        {{ getHeader.description }}
      </p>
    </div>
    <div class="section-body">
      <div class="card main-card">
        <div class="split-text">
          <h3 class="card-title">{{ getComponentData.title }}</h3>
          <p class="card-description">
            {{ getComponentData.description }}
          </p>
          <div class="link-grp">
            <btn
              :href="getComponentData.link_url"
              :text="getComponentData.link_text"
              custom-class="action-link-renewed"
            />
          </div>
        </div>
        <div class="split-image">
          <img :src="HOME_PRIVACY" alt="" width="524" height="476"/>
        </div>
      </div>
      <div class="card sub-card benchmarks">
        <img :src="HOME_BENCHMARKS" alt="" width="314"/>
      </div>
      <div class="card sub-card hipaa">
        <img :src="HOME_HIPAA" alt="" width="180"/>
      </div>
      <div class="card sub-card soc2">
        <img :src="HOME_SOC2" alt="" width="104"/>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/basic/Btn.vue';
import cloudinary from '@/constants/cloudinary';

export default {
  props: {
    headerData: {
      type: Object,
      default() {
        return {};
      },
    },
    securityData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Btn,
  },
  computed: {
    getHeader() {
      return this.headerData;
    },
    getComponentData() {
      return this.securityData;
    },
  },
  created() {
    this.HOME_PRIVACY = cloudinary.HOME_PRIVACY;
    this.HOME_SOC2 = cloudinary.HOME_SOC2;
    this.HOME_HIPAA = cloudinary.HOME_HIPAA;
    this.HOME_BENCHMARKS = cloudinary.HOME_BENCHMARKS;
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/global/marketing.scss';

.section-body {
  display: grid;
  grid-template-rows: 1fr repeat(3, 5.25rem);
  gap: 0.5rem;

  p {
    color: $section-security;
  }

  @include bp-mediumsmall {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 5.25rem 5.25rem;
  }

  @include bp-medium {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 5.25rem;
  }

  @include bp-xlarge {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 10.5rem;
    gap: 1rem;
  }

  @include bp-large {
    gap: 1.5rem;
  }
}

.main-card {
  @include bp-mediumsmall {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
  }

  @include bp-medium {
    grid-column-end: 4;
    grid-row-end: 2;
  }

  @include bp-xlarge {
    grid-column-end: 5;
  }
}

.sub-card {
  @include bp-mediumsmall {
    grid-row-start: 2;
  }
}

.benchmarks {
  grid-row-start: 4;

  @include bp-mediumsmall {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
  }

  @include bp-medium {
    grid-column-end: 2;
    grid-row-start: 2;
  }

  @include bp-xlarge {
    grid-column-end: 3;
  }
}

.sub-card img {
  width: auto;
  max-height: 100%;
}

.soc2 {
  img {
    max-width: 6.5rem;
  }
}

.hipaa {
  img {
    max-width: 11.25rem;
  }
}

.benchmarks {
  img {
    max-width: 100%;

    @include bp-xlarge {
      max-width: 19.625rem;
    }
  }
}

.card {
  @include card-basic();

  @extend %split;
}

.split-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-basis: max(50%, 457px);
}

.split-image {
  @include bp-xlarge {
    overflow: hidden;
  }

  img {
    max-width: 100%;
    height: auto;

    @include bp-xlarge {
      max-width: none;
    }
  }
}

.sub-card {
  @include flex-center;
}

.main-card {
  .split-text {
    text-align: center;
    order: 2;

    @include bp-medium {
      text-align: left;
      order: initial;
    }
  }

  @include bp-xlarge {
    padding-right: 0;
  }
}

.section-header {
  @include section-title-framework();
}

.section-title {
  @extend %section-title-font;
  padding-left: 0;
  text-align: left;
}

.card-title {
  @include card-title-font();
  margin-bottom: 0.5rem;
}

.card-description {
  @include card-description-font();
}
</style>
