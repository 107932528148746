<template>
  <div class="he-section">
    <div class="section-header">
      <h2 class="section-title">{{ getHeader.title }}</h2>
      <p class="section-description">
        {{ getHeader.description }}
      </p>
    </div>
    <div class="section-body" v-if="getCards.length">
      <section
        :class="getCardClasses(card.meta.id)"
        v-for="card in getCards"
        :key="card.meta.id"
      >
        <picture v-if="card.meta.id === 167072">
          <img
            :src="card.image_url_alt"
            :alt="card.image_alt_text"
            class="logo-img"
          />
        </picture>
        <picture v-else>
          <source :srcset="card.image_url_alt" media="(min-width: 1280px)"/>
          <source :srcset="card.image_url_alt" media="(min-width: 992px)"/>
          <source :srcset="card.image_url" media="(min-width: 320px)"/>
          <img
            :src="card.image_url"
            :alt="card.image_alt_text"
            class="feature-icon"
          />
        </picture>
        <div class="card-content">
          <h3 class="card-title" v-if="card.card_title">{{ card.card_title }}</h3>
          <p class="card-description">
            {{ card.card_description }}
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Array,
      default() {
        return [];
      },
    },
    headerData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    getCards() {
      return this.cardData;
    },
    getHeader() {
      return this.headerData;
    },
  },
  methods: {
    getCardClasses(customClass) {
      const carClassArr = ['card'];
      carClassArr.push(`card-${customClass}`);
      return carClassArr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/global/marketing.scss';

.section-header {
  @include section-title-framework();
}

.section-title {
  @extend %section-title-font;
  padding-left: 0;
  text-align: left;
  margin-bottom: 0.5rem;
}

.section-description {
  @include card-description-font();
  max-width: 47.5rem;
  line-height: 1.5rem;
  @include bp-xlarge {
    line-height: 2.0625rem;
  }
}

.card {
  @include card-bg-color(#fff);
  h3 {
    margin: 0;
    color: #293447;
    margin-top: 3rem;
  }
  border-radius: 1rem;
  padding: 1.5rem;
  @include bp-mediumsmall {
    border-radius: 1.25rem;
    padding: 1.5rem;
  }
  @include bp-xlarge {
    border-radius: 2rem;
    padding: 1.5rem 2.5rem;
  }
}

.card-title {
  @include card-title-font();

  color: $section-primary;
  margin-bottom: 0.5rem;

  @include bp-xlarge {
    margin-bottom: 1rem;
  }
}

.card-description {
  font-weight: 500;
  color: #000;

  @include card-description-font();

  @include bp-small{
    margin-top: 1rem;
  }
  @include bp-mediumsmall{
    margin-top: 1rem;
  }
}

.section-body {
  display: grid;
  gap: 1.5rem;
  @include bp-xs{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include bp-mediumlarge{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.section-body .card:nth-child(7) {
  @include bp-xs{
    height: 35rem;
  }
  @include bp-medium{
    height: auto;
  }
  @include bp-mediumlarge{
    height: 37.875rem;
  }
  @include bp-xlarge {
    height: auto;
  }
  @include bp-large {
    height: 65.625rem;
  }
}
// Banner
.card-167072 {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: span 2;
  align-items: center;
  place-items: center;
  display: grid;
  .card-content {
    grid-column-end: span 1;
    h3 {
      margin-top: 1rem;
    }
  }
  @include bp-xs {
    grid-template-columns: none;
    padding: 3.75rem 1.5rem;
  }
  @include bp-small{
    padding: 3.75rem 1.5rem;
  }
  @include bp-mediumlarge {
    grid-template-columns: 2.5fr 1.5fr;
    grid-column-end: span 4;
    align-items: center;
    padding: 5.75rem 2.5rem;
    .card-content {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-column-end: unset;
      h3 {
        margin-top: 0;
      }
    }
    picture {
      grid-row-start: 1;
      grid-column-start: 2;
    }
  }
  @include bp-large {
    grid-template-columns: 1.5fr 1.5fr;
  }
}
// Mobile
.card-167073 {
  align-items: center;
  display: grid;
  @include bp-xs{
    grid-row-start: 2;
    grid-column-start: 1;
  }
  @include bp-mediumlarge{
    grid-row-start: 2;
    grid-column-start: 1;
  }
}
// Support
.card-167074 {
  @include bp-xs{
    grid-row-start: 2;
    grid-column-start: 2;
  }
  @include bp-mediumlarge{
    grid-row-start: 2;
    grid-column-start: 2;
  }
}
// Accessibility
.card-167075 {
  @include bp-xs{
    grid-row-start: 3;
    grid-column-start: 1;
  }
  @include bp-mediumlarge{
    grid-row-start: 2;
    grid-column-start: 3;
  }
}
// Multilanguage
.card-167076 {
  @include bp-xs{
    grid-row-start: 3;
    grid-column-start: 2;
  }
  @include bp-mediumlarge{
    grid-row-start: 2;
    grid-column-start: 4;
  }
}
.logo-img {
  max-width: 10rem;
  @include bp-xs {
    max-width: 15rem;
  }
  @include bp-small {
    max-width: 20rem;
  }
  @include bp-medium {
    max-width: 25rem;
  }
  @include bp-mediumlarge {
    max-width: 25rem;
  }
  @include bp-large {
    max-width: 28rem;
  }
}
.feature-icon {
  width: 2.5rem;
  @include bp-mediumsmall {
    width: 3rem;
  }
}
</style>
