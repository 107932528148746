<template>
  <div class="home-main">
    <div :class="wrapper">
      <template v-if="childDataLoaded">
        <gradient-canvas/>
        <section class="card hero">
          <div class="hero-main-content-container">
            <div class="hero-text-btn-container">
              <div class="hero-title-container">
                <h1 class="hero-title" v-html="getHero.headline_html"/>
              </div>
              <div class="hero-buttons-container">
                <btn
                  custom-class="button-link"
                  :text="getHero.support_button_text"
                  @btn-click="openZendeskWidget"/>
              </div>
            </div>
            <div class="hero-logo-container">
              <div class="hero-partners-container">
                <div class="partner-image-container"
                    :class="{'byu': partner.image_alt === 'BYU logo'}"
                    v-for="(partner, index) in getHero.partners"
                    :key="index">
                  <img :src="partner.image_link" :alt="partner.image_alt"/>
                </div>
              </div>
            </div>
          </div>
          <div class="cta-button-container" :style="cssVars">
            <img src="/images/chevron.png" alt="chevron" class="chevron-down"/>
            <div class="cta-link" @click="scrollToSpot">
              {{ getHero.cta_button_text }}
            </div>
          </div>
        </section>
        <section class="section">
          <impact-section
            :cardData="getCardComponent"
            :headerData="getHeaderData[0]"
          />
        </section>
        <section class="section">
          <cs-section
            :cardData="customerSuccessData"
            :headerData="getHeaderData[4]"
          />
        </section>
        <section class="section">
          <he-section
            :cardData="healthEquityData"
            :headerData="getHeaderData[5]"
          />
        </section>
        <section class="section security">
          <security-section
            :securityData="getSecurityData"
            :headerData="getHeaderData[2]"
          />
        </section>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Btn from '@/components/basic/Btn.vue';
import SecuritySection from '@/components/basic/SecuritySection';
import ImpactSection from '@/components/basic/ImpactSection';
import CustomerSuccessSection from '@/components/basic/CustomerSuccessSection';
import HealthEquitySection from '@/components/basic/HealthEquitySection';
import Gradient from '@/components/basic/Gradient';
import cloudinary from '@/constants/cloudinary';
import { butter } from '@/buttercms';

export default {
  name: 'Home',
  components: {
    Btn,
    ImpactSection,
    'cs-section': CustomerSuccessSection,
    'he-section': HealthEquitySection,
    SecuritySection,
    'gradient-canvas': Gradient,
  },
  data() {
    return {
      title: 'Home',
      wrapper: 'home-wrapper',
      thisPage: {},
      childDataLoaded: false,
    };
  },
  computed: {
    ...mapState(['showPreview']),
    getAdComponent() {
      return this.childDataLoaded ? this.thisPage.fields.ad_component : [];
    },
    getCardComponent() {
      return this.childDataLoaded ? this.thisPage.fields.impact_section : [];
    },
    getFeaturesComponent() {
      return this.childDataLoaded ? this.thisPage.fields.features_section : [];
    },
    customerSuccessData() {
      return this.childDataLoaded ? this.thisPage.fields.customer_success_section : [];
    },
    healthEquityData() {
      return this.childDataLoaded ? this.thisPage.fields.health_equity_section : [];
    },
    getHeaderData() {
      return this.childDataLoaded ? this.thisPage.fields.section_headings : [];
    },
    getHero() {
      return this.childDataLoaded ? this.thisPage.fields.hero : [];
    },
    getSecurityData() {
      return this.childDataLoaded ? this.thisPage.fields.security : {};
    },
    cssVars() {
      const isAndroid = (/(Android)/i.test(navigator.userAgent));

      return {
        '--ctaButtonMarginBottomStart': isAndroid ? '6rem' : '3.5rem',
        '--ctaButtonMarginBottomEnd': isAndroid ? '5.5rem' : '3rem',
      }
    },
  },
  created() {
    this.RIGHT_ARROW = cloudinary.RIGHT_ARROW;
    this.HOME_HEALTH_N_SAFETY_DESKTOP = cloudinary.HOME_HEALTH_N_SAFETY_DESKTOP;
    this.fetchCms();
    this.$root.$emit('add-class-to-app', ['home']);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != 'home') {
      this.$root.$emit('add-class-to-app', []);
    }
    next();
  },
  methods: {
    fetchCms() {
      const locale = this.$route.params.locale;
      butter.page
        .retrieve('*', 'home-alt', {
          locale: locale,
          preview: this.showPreview ? 1 : 0,
        })
        .then(resp => {
          this.thisPage = resp.data.data;
          this.childDataLoaded = true;
        })
        .catch(function(resp) {
          console.log(resp);
        });
    },
    scrollToSpot() {
      const navbarOffset = document.querySelector('#nav').offsetHeight;
      const el = document.querySelector('.impact-section');
      window.scroll({
        top: el.offsetTop - navbarOffset,
        left: 0,
        behavior: 'smooth',
      });
    },
    openZendeskWidget(){
      this.$zendesk.open();
    },
  },
};
</script>

<style lang="scss">
@import '../styles/global/marketing.scss';

.content.home {
  overflow: hidden;
  padding-bottom: 0;
}
#app.home {
  background-color: $bg-main-color;
}

.home {
  .content {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar {
    @include fullwidth-padding-framework();
  }

  .footer {
    @include fullwidth-padding-framework();

    @include bp-mediumlarge {
      grid-column-gap: 0;
      grid-template-columns: 1fr;
    }

    @include bp-xlarge {
      grid-column-gap: 2.5rem;
      grid-template-columns: 1fr 1fr;
    }

    .navigation {
      @include bp-mediumsmall {
        grid-template-columns: none;
      }
      @include bp-medium {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  p,
  h1,
  h2,
  h3 {
    color: $general;
  }

  p {
    margin-bottom: 0;
  }
}
</style>

<style scoped lang="scss">
@import '../styles/global/marketing.scss';

.section {
  @extend %section-alt;
}

.security {
  @extend %section-last;
}

.home-wrapper {
  @include compact-padding-framework();
}

.card {
  height: calc(max(#{$hero-min-height}, 100vh) - #{$offsetDueToNavMobile});
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.hero {
    padding: 0 1rem;
    position: relative;
    @include bp-medium {
      text-align: center;
      padding: 0;
    }
  }

  .split-img {
    order: 1;
    @include bp-medium {
      order: 2;
    }
  }
}

.hero-main-content-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 10rem;
  
  @include bp-medium {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
}

.hero-text-btn-container,
.hero-logo-container {
  width: 100%;
}

.hero-logo-container {
  animation-name: loadHeroLogo;
  animation-duration: 1.2s;

  @include bp-mediumsmall {
    margin-top: 0;

    @keyframes loadHeroLogo {
      from {
        margin-top: 1rem;
      }
      to {
        margin-top: 0;
      }
    }
  }

  @include bp-medium {
    margin-top: 1.5rem;

    @keyframes loadHeroLogo {
      from {
        margin-top: 5.5rem;
      }
      to {
        margin-top: 1.5rem;
      }
    }
  }
}

.hero-title-container {
  text-align: left;
  width: 95%;
  margin-bottom: 2rem;

  animation-name: loadHeroTitle;
  animation-duration: 1.2s;
  @keyframes loadHeroTitle {
    from {
      margin-top: 4rem;
    }
    to {
      margin-top: 0;
    }

    0%  {margin-bottom: 2rem;}
    25%  {margin-bottom: 3rem;}
    100%  {margin-bottom: 2rem;}
  }

  @include bp-medium {
    padding: 0 2rem 0 1.5rem;
  }
}

.hero-buttons-container {
  display: flex;

  @include bp-medium {
    padding-left: 1.5rem;
    justify-content: left;
    margin-top: 2rem;
  }

  .button-link {
    background-color: #002B5E;
    margin-right: 0.5rem;
    padding: 0.5rem 0.7rem;
    width: auto;
    transition-duration: 0.2s;
    @include bp-small {
      margin-right: 1rem;
    }
    @include bp-medium {
      padding: 0.5rem 1rem;
    }
    &:active {
      transform: scale(0.97);
    }
  }

  animation-name: loadHeroButton;
  animation-duration: 1.2s;
  @keyframes loadHeroButton {
    0%  {margin-top: 3rem;}
    25%  {margin-top: 3rem;}
    100%  {margin-top: 2rem;}
  }
}

.hero-partners-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 2.9rem;
  grid-gap: 1.1rem;

  img {
    display: block;
    width: 100%;
    opacity: 0.8;
    transition-duration: 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  @include bp-medium {
    margin-top: 0;
  }
}

.hero-title {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.7rem;
  margin-bottom: 0.5rem;
  color: #ffffff;

  @include bp-mediumsmall {
    font-size: 3.5rem;
    line-height: 4.2rem;
  }
  @include bp-medium {
    font-size: 2.5rem;
    line-height: 120%;
    max-width: 32rem;
    margin: 0 auto 1rem;
  }

  @include bp-mediumlarge {
    font-size: 3rem;
  }

  @include bp-large {
    font-size: 4.25rem;
    line-height: 5.1rem;
    max-width: 42rem;
  }
}

.description {
  font-size: 0.875rem;
  line-height: 1.225rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  @include bp-mediumsmall {
    font-size: 1.375rem;
    line-height: 1.925rem;
  }
  @include bp-medium {
    font-size: 1rem;
    line-height: 1.4rem;
    max-width: 25rem;
    margin: 0 auto 1.5rem;
  }
  @include bp-large {
    font-size: 1.375rem;
    line-height: 1.788rem;
    max-width: 35rem;
    margin-bottom: 2rem;
  }
}

.hero .split-img {
  margin-bottom: 1.5rem;

  img {
    height: 100%;
  }
}

.split-img {
  overflow: hidden;

  img {
    display: block;
    max-height: 355px;

    @include bp-medium {
      max-height: none;
      width: 37.5rem;
    }
    @include bp-xlarge {
      width: 41.563rem;
    }
  }
}

.button-link {
  ::v-deep .btn__content {
    font-size: 0.8125rem;

    @include bp-xlarge {
      font-size: 0.9375rem;
    }
  }

  @include bp-medium {
    max-width: 12.5rem;
    width: 90%;
  }
}

.cta-button-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: var(--ctaButtonMarginBottomEnd);
  position: absolute;
  bottom: 0;
  transition-duration: 0.2s;

  img {
    margin-right: 0.5rem;
  }

  &:hover {
    cursor: pointer;

    @include bp-mediumlarge {
      margin-bottom: 2.85rem;
    }
  }

  &:active {
    opacity: 0.6;
  }

  @include bp-medium {
    margin-left: 1.5rem;
  }

  animation-name: loadCtaButton;
  animation-duration: 1.2s;
  @keyframes loadCtaButton {
    0%  {margin-bottom: var(--ctaButtonMarginBottomStart); opacity: 0;}
    30%  {margin-bottom: var(--ctaButtonMarginBottomStart); opacity: 0;}
    100%  {margin-bottom: var(--ctaButtonMarginBottomEnd); opacity: 1;}
  }
}

.cta-link {
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: #ffffff;
}

.special-cta {
  order: 1;
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  padding: 0.3rem 0.6rem;
  border-radius: 1.2rem;
  background-color: #eefff2;
  text-decoration: none;
  color: inherit;

  span {
    margin-right: 0.3rem;
    padding: 0.25rem 0.5rem;
    border-radius: 1.3rem;
    background-color: #7ae898;
    font-size: 0.8rem;
    letter-spacing: 0.2rem;
  }

  .pointer {
    margin-left: 0.25rem;
  }

  @include bp-mediumlarge {
    display: none;
  }
}
</style>
