<template>
  <canvas id="gradient-canvas" :style="cssVars">
  </canvas>
</template>

<script>
import { Gradient } from '@/utils/animatedGradient';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['isAndroidSmartBannerVisible']),
    getWidth() {
      return window.innerWidth;
    },
    getHeight() {
      return window.innerHeight;
    },
    cssVars() {
      let offsetDueToAndroidSmartBanner = '0rem';
      if (this.isAndroidSmartBannerVisible) {
        offsetDueToAndroidSmartBanner = window.innerWidth <= 320 ? '6.76rem' : '5.26rem';
      }
      return {
        '--offsetDueToAndroidSmartBanner': offsetDueToAndroidSmartBanner,
      }
    },
  },
  methods: {
    startGradient() {
      let gradient = new Gradient;
      gradient.initGradient('#gradient-canvas');
      gradient.connect();
      return;
    },
  },
  mounted() {
    this.$nextTick(() => { this.startGradient(); });
  },
};
</script>

<style lang="scss" scoped>
#gradient-canvas {
  width: 100%;
  height: calc((max(#{$hero-min-height}, 100vh)) + var(--offsetDueToAndroidSmartBanner));
  --gradient-color-1: #5CC5F2;
  --gradient-color-2: #268BFF;
  --gradient-color-3: #7A7CFF;
  --gradient-color-4: #7A7CFF;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;

  @include bp-xlarge {
    height: calc(max(#{$hero-min-height}, 100vh) + 2rem + var(--offsetDueToAndroidSmartBanner));
  }
}
</style>
